import { Grid, Page } from "@geist-ui/core";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navigation from "./Navigation";

export default function PageContainer(props) {

  let location = useLocation()
  var [showNavigation, setShowNavigation] = useState(true)

  useEffect(() => {
    if (location.pathname.indexOf("/authenticate")>=0) {
      setShowNavigation(false)
    }
  }, [location])

  return (
    <>
      <Grid.Container gap={2} justify="center" style={{maxWidth: showNavigation ? 1280 : "100%"}}>
        { showNavigation && 
        <Grid xs={showNavigation ? 6 : 0}>          
          <Navigation/>
        </Grid>
        }
        <Grid xs={showNavigation ? 18 : 24}>
          <Page>
            <Page.Content>
              <Outlet />
            </Page.Content>
          </Page>
        </Grid>
      </Grid.Container>
    </>
  )
}
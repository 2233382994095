/*
  The incoming date is in the format YYYY-MM-DD, and the returned date
  also needs to be in that format. So we'll have to convert at the right
  places to make sure we abstract the calendar picker a bit here.
*/

import { Input } from "@geist-ui/core"
import { useEffect, useState } from "react"
import { Calendar } from "react-calendar"
import { Time } from "../helpers/time"
import 'react-calendar/dist/Calendar.css';

export default function DatePicker(props) {
  const [currentDate, changeDate] = useState(new Date())

  const updateDate = (d) => {
    props.whenClosed()
    props.onChange(Time.getShortFormatDate(d))
  }

  useEffect(() => {
    changeDate(Time.shortFormatDateToFullDate(props.date))
  }, [props])

  const onBlurHandler = (e) => {
    // TODO: Safari blocks the date handler completely if we're trying to use the blur handler here.
    // This needs to be fixed to allow a click away from the date picker to make it disappear.
    // This works in Chrome, but not in Safari at the moment.
    try {
      if (window.navigator.vendor.indexOf('Apple') >= 0) {
        return false
      }
    } catch (e) {}
    setTimeout(() => {
      if (e.relatedTarget && e.relatedTarget.classList.value.indexOf('react-calendar') >= 0) {
        // Click on calendar, so do nothing
      } else {
        if (props.onBlur) props.onBlur()
      }
    }, 0)
  }

  return (
    <div onBlur={onBlurHandler} tabIndex={-1} style={{ position: 'relative' }}>
      <div style={{fontSize:11, color:"#666"}}>{props.label || ''}</div>
      <Input 
        readOnly 
        value={Time.getHumanReadableDate(currentDate)}
        id={props.id || ''}
        placeholder={props.placeholder||""}
        onClick={() => {
          props.whenOpened()
        }}
      />
      {props.isOpen && (
        <div
          id="date-field-1"
          style={{ position: 'absolute', zIndex: 100, backgroundColor: 'red' }}>
          <Calendar
            id="date-field"
            maxDate={props.maximumDate || Time.getDateByDayOffset(new Date() + 365 * 2)}
            minDate={props.minimumDate || new Date()}
            minDetails={'decade'}
            showWeekNumbers={true}
            onChange={updateDate}
            value={currentDate}
          />
        </div>
      )}
    </div>
  )
}

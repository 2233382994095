import ExportDetails from "../components/ExportDetails";

export default function ReportClimatePage() {
  return (
    <div>
      <h3>Climate Report</h3>
      <br/>
      <ExportDetails report={"co2"}/>

    </div>
  )
}
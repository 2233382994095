import { AutoComplete, Button, Card, Grid, Loading } from "@geist-ui/core";
import { useEffect, useState } from "react";
import { useStore } from "../stores/main";
import { API } from "../helpers/api";

export default function AdminCostCenterPage() {

  const [preloading, setPreloading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [updatingOriginalId, setUpdatingOriginalId] = useState(false);
  const [updatingDescription, setUpdatingDescription] = useState(false);
  const [currentCostCenter, setCurrentCostCenter] = useState(false)

  const cost_centers = useStore((store) => store.cost_centers)
  const setCostCenters = useStore((store) => store.setCostCenters)

  useEffect(() => {
    const fetchCostCenters = async () => {
      var data = await API.getCostCenters();
      setCostCenters(data.costcenters);
      setPreloading(false);
    };
    if (preloading === false && cost_centers.length === 0) {
      setPreloading(true);
      fetchCostCenters();
    }
  },[preloading, cost_centers, setCostCenters]);

  const search = (q) => {
    if (!q) return setResults([])
    var cq = q.toLowerCase();
    setSearching(true);
    let r = cost_centers.filter((c) => c.original_id.toLowerCase().indexOf(cq)>=0 || c.description.toLowerCase().indexOf(cq)>=0);    
    setResults(r.slice(0,20).map((rs) => {

      return (
        <AutoComplete.Option value={rs.cost_center_id}>
          <div style={{marginTop:5, marginBottom:5}}>
            <div style={{fontSize:16, fontWeight:"bold"}}>{rs.original_id}</div>
            <div style={{color:"#666"}}>{rs.description}</div>
          </div>
        </AutoComplete.Option>
      )

      /*
      return {
        label:rs.original_id, 
        value:rs.cost_center_id
      }*/
    }));
    setSearching(false);
  };

  const grabCostCenterById = (id) => {
    return cost_centers.filter((c) => c.cost_center_id === id)[0];
  };

  const updateLocalCostCenter = (cost_center) => {
    // We'll overwrite our local copy of the cost center list
    // so don't need to fetch the entire list again!
    var new_cost_centers = cost_centers.map((c) => {
      if (c.cost_center_id === cost_center.cost_center_id) {
        // Check if we just disabled this cost center
        if (cost_center.enabled === false) {
          return null
        } else {
          return cost_center;
        }
      } else {
        return c;
      }
    }).filter((c) => c !== null);
    setCostCenters(new_cost_centers)
    setCurrentCostCenter(cost_center)
  }

  const onRename = () => {
    setUpdatingOriginalId(true);
    var newOriginalId = prompt("Please enter the new Cost Center ID", currentCostCenter.original_id);
    if (newOriginalId) {
      var updated_cost_center = {...currentCostCenter, original_id: newOriginalId};
      API.postCostCenter(updated_cost_center)
      .then((data) => {
        updateLocalCostCenter(updated_cost_center); 
      }).catch((error) => {
        alert("Sorry, we were unable to update this cost center. Please try again or reach out to us if this continues to happen.")
      }).finally(() => {        
        setUpdatingOriginalId(false)
      })  
    } else {
      setUpdatingOriginalId(false)
    }
  }

  const onEdit = () => {
    setUpdatingDescription(true);
    var newDescription = window.prompt(`Please enter the updated description for this cost center: ${currentCostCenter.original_id}`, currentCostCenter.description);
    if (newDescription) {
      var updated_cost_center = {...currentCostCenter, description: newDescription};
      API.postCostCenter(updated_cost_center)
      .then((data) => {
        updateLocalCostCenter(updated_cost_center); 
      }).catch((error) => {
        alert("Sorry, we were unable to update this cost center. Please try again or reach out to us if this continues to happen.")
      }).finally(() => {
        setUpdatingDescription(false)
      })  
    } else {
      setUpdatingDescription(false)
    }
  }

  const onDisable = () => {
    var sure = window.confirm(`Are you sure? This will disable ${currentCostCenter.original_id} and make it inaccessible to your users.`);
    if (sure) {
      var updated_cost_center = {...currentCostCenter, enabled: false};
      API.postCostCenter(updated_cost_center)
      .then((data) => {
        updateLocalCostCenter(updated_cost_center);
        setCurrentCostCenter(false)
      }).catch((error) => {
        alert("Sorry, we were unable to update this cost center. Please try again or reach out to us if this continues to happen.")
      })
    }
  }  

  return (
    <div>
      <h3>{currentCostCenter ? `Editing: ${currentCostCenter.original_id}` : "Cost Center Administration"}</h3>
      <br/>

      {preloading && <div style={{textAlign:"center", fontSize: 12, color: "#777", marginTop:100, maxWidth: 400, marginLeft:"auto", marginRight:"auto", borderRadius: 10, padding: 20, boxShadow: "2px 2px 3px #ddd"}}>
        One moment, we're loading all the cost centers...<br/><br/>
        <Loading spaceRatio={2.5} />
      </div>}

      {!preloading && 
      <div>
        {!currentCostCenter && <>
        <h4>Find Cost Center</h4>
        <AutoComplete onSearch={search} onSelect={(cid) => { setCurrentCostCenter(grabCostCenterById(cid)) }} searching={searching} placeholder="Type Cost Center ID or Project" width="300px" clearable options={results}>
          <AutoComplete.Searching>
            <span style={{ color: 'blue' }}>searching ...</span>
          </AutoComplete.Searching>
        </AutoComplete>
        </>
        }
        {currentCostCenter && <>
          <div style={{display:"flex", alignContent: "center"}}>
            <Button auto onClick={() => { setCurrentCostCenter(false) }}>⃪&nbsp; Go Back</Button>
          </div>
          <br/><br/>
          <h5>Cost Center Details</h5>
          <div style={{borderBottom:"1px solid #ddd", marginBottom:10, marginTop:5}}></div><br/>
          <div>
            <Grid.Container gap={2} justify="flex-start" alignItems={"center"}>
              <Grid xs={7} style={{color:"#888", fontSize: 13}}>Internal ID</Grid>
              <Grid xs={10}><Card shadow width="100%" height="50px" style={{fontSize:12, color: "#888"}}>{currentCostCenter.cost_center_id}</Card></Grid>
            </Grid.Container>
            <Grid.Container gap={2} justify="flex-start" alignItems={"center"}>
              <Grid xs={7} style={{fontSize: 13}}>Cost Center ID</Grid>
              <Grid xs={10}>
                <Card shadow width="100%" height="50px" style={{display:"flex", alignItems: "center", fontSize:12, color: "#666"}}>
                  {!updatingOriginalId &&
                    <>
                      <span style={{fontWeight:"bold"}}>{currentCostCenter.original_id}</span>
                      <Button style={{marginLeft:15}} auto scale={0.25} onClick={onRename}>Rename</Button>
                    </>
                  }
                  {updatingOriginalId && <Loading/> }
                </Card>
              </Grid>
            </Grid.Container>
            <Grid.Container gap={2} justify="flex-start" alignItems={"center"}>
              <Grid xs={7} style={{fontSize: 13}}>Cost Center Description</Grid>
              <Grid xs={16}>
                <Card shadow width="100%" height="50px" style={{display:"flex", alignItems: "center", fontSize:12, color: "#666"}}>
                  {!updatingDescription && <>
                    <span style={{fontWeight:"bold"}}>{currentCostCenter.description}</span>
                    <Button style={{marginLeft:15}} auto scale={0.25} onClick={onEdit}>Edit</Button>
                  </>}
                  {updatingDescription && <Loading/> }
                </Card>
              </Grid>
            </Grid.Container>

          </div>
          <div>
            <br/><br/>
            <h5>Cost Center Actions</h5>
            <div style={{borderBottom:"1px solid #ddd", marginBottom:10, marginTop:5}}></div><br/>
            <Button type="error" ghost auto onClick={onDisable}>Disable Cost Center</Button>
          </div>
        </>        
        }

      </div>
      }

    </div>
  )
}
import { Button, Display, Input, Loading } from "@geist-ui/core";
import { useState } from "react";
import { API } from "../helpers/api";
import { useStore } from "../stores/main";

export default function AuthenticationPage() {
  
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const setUser = useStore((store) => store.setUser)
  const [attempting, setAttempting] = useState(false);

  const attemptLogin = async () => {
    setAttempting(true);
    const user = await API.login(username, password)
    if (user) {
      setUser(user)
      setAttempting(false)
    } else {
      setAttempting(false)
    }
  }
 
  return (
    <div style={{maxWidth:400, width: "100%", marginLeft: "auto", marginRight: "auto", marginTop:200}}>
      <Display shadow>
        <div style={{padding:40, textAlign: "left"}}>
          <div style={{textAlign:"center", marginBottom:20, color: "#999", fontSize: 12, letterSpacing: 1.8}}>REPORTING</div>
          <div style={{marginBottom:50, textAlign: "center"}}><img style={{width:"60%", marginLeft: "auto", marginRight: "auto"}} src="/logo.png" alt="Logo"/></div>
          <Input placeholder="E-mail" value={username} onChange={(d) => { setUsername(d.target.value) }} size="100"><span style={{fontSize:12}}>Username</span></Input>
          <br/><br/>
          <Input.Password placeholder="" value={password} onChange={(d) => { setPassword(d.target.value) }} size="100"><span style={{fontSize:12}}>Password</span></Input.Password>
          <br/><br/>
          <div style={{textAlign: "center", marginTop:20}}>
            {attempting && <Loading/>}
            {!attempting  && <Button type="success" onClick={attemptLogin}>Sign in</Button>}
          </div>
        </div>
      </Display>
    </div>
  )
}
import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Loading } from '@geist-ui/core'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { useStore } from './stores/main'
import { API } from './helpers/api'
import PageContainer from './components/PageContainer'
import AuthenticationPage from './pages/Authenticate'
import ReportCostCenterPage from './pages/ReportCostCenter'
import ReportClimatePage from './pages/ReportClimate'
import AdminCostCenterPage from './pages/AdminCostCenter'

const router = createBrowserRouter([
  {
    path: "/report",
    element: <PageContainer />,
    children: [
      {
        path: "/report/costcenter",
        element: <ReportCostCenterPage />,
      },
      {
        path: "/report/climate",
        element: <ReportClimatePage />,
      },
    ]
  },
  {
    path: "/admin",
    element: <PageContainer />,
    children: [
      {
        path: "/admin/costcenter",
        element: <AdminCostCenterPage />,
      },
    ]
  },
  {
    path: "*",
    element: <Navigate to="/report/costcenter" replace />
  }
])

export default function Router() {

  const authenticated = useStore((store) => store.authenticated)
  const setUser = useStore((store) => store.setUser)
  const setCompany = useStore((store) => store.setCompany)
  const [authenticationAttempted, setAuthenticationAttempted] = useState(false)

  const attemptAuthentication = () => {
    return new Promise(async(resolve, reject) => {
      // First, let's get the AWS Amplify/Cognito user
      var user = false;
      try {
        user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      } catch (error) {
        console.log("AWS Response: ", error)
        reject("no_user")
        return;
      }
      if (user) {
        // Now let's grab the company
        let response = await API.getCompany();
        if (response && response.company) {
          // All done, let's return
          resolve({company: response.company, user: user})
        } else {
          reject("no_company")
        }
      } else {
        reject("no_user")
      }
    })
  }

  useEffect(() => {
    if (!authenticationAttempted) {
      attemptAuthentication()
      .then((auth) => {
        setCompany(auth.company)
        setUser(auth.user)
      }).catch((error) => { 
        console.log("Okay, we have no user logged in at the moment...")
      }).finally(() => { setAuthenticationAttempted(true) })  
    }
  }, [authenticationAttempted, setCompany, setUser])

  if (!authenticationAttempted) {
    return <div style={{marginTop:300, textAlign: "center"}}><Loading/></div>
  }

  return authenticated ? <RouterProvider router={router}/> : <AuthenticationPage/>
}
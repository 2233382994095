import { create } from 'zustand'

export const useStore = create((set, get) => ({
  user: false,
  company: false,
  authenticated: false,
  cost_centers: [],
  setUser: (userData) => {
    var authenticated = true;
    if (!userData || userData === null) authenticated = false;
    set({user: userData, authenticated: authenticated})
  },
  setCompany: (companyData) => {
    set({company: companyData})
  },
  setCostCenters: (costCenters) => {
    set({cost_centers: costCenters })
  },
  logout: () => {
    set({user: false, company: false, authenticated: false})
  }
}))
import { Auth } from 'aws-amplify';
import axios from 'axios'

export const API = {

  root: document.location.hostname === "localhost" ? "http://localhost:8080/" : process.env.REACT_APP_API,

  // This is a wrapper for API REST calls (POST) that need to be authenticated via AWS Cognito
  _post: (url, data, expect_file=false) => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((session) => {
          /* The call needs AWS Cognito Credentials */

          var request = { 
            headers: {
              Authorization: `${session.idToken.jwtToken}`,
            }
          }
          if (expect_file) {
            request["responseType"] = "blob"
            request["headers"]["Accept"] = "application/octet-stream" // This forces a binary download from AWS Gateway/Lambda
          }

          var clean_url = url;
          if (url[0] === '/') clean_url = url.substring(1);

          axios
            .post(`${API.root}${clean_url}`, data, request)
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              console.log('Error: ', error)
              reject(error)
            })
        })
        .catch((error) => {
          console.trace('Error while trying to send a POST request: ', error)
          // Unauthorized? Let's hard-redirect to the root
          setTimeout(() => {
            document.location.href = '/'
          }, 500)
          reject(error)
        })
    })
  },

  // This is a wrapper for API REST calls (GET) that need to be authenticated via AWS Cognito
  _get: (url, data) => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((session) => {

          var clean_url = url;
          if (url[0] === '/') clean_url = url.substring(1);

          // Prepare the URL params
          let urlParameters =
            !data || data.length === 0
              ? ''
              : '?' +
                Object.entries(data)
                  .map((e) => e.join('='))
                  .join('&')

          /* The call needs AWS Cognito Credentials */
          axios
            .get(`${API.root}${clean_url}${urlParameters}`, {
              headers: {
                Authorization: `${session.idToken.jwtToken}`,
                'X-Frontend-Version': window.fnzBuildVersion || '0.0'
              }
            })
            .then((res) => {
              resolve(res.data)
            })
            .catch((error) => {
              console.log('Error: ', error)
              reject(error)
            })
        })
        .catch((error) => {
          console.trace('[Amplify Error] while trying to send a GET request: ', error)
          reject(error)
        })
    })
  },

  getCompany: () => {
    return API._get(`/v1/company`)
  },

  getCostCenters: () => {
    return API._get(`/v1/company/costcenters`)
  },

  postCostCenter: (payload) => {
    return API._post(`/v1/company/costcenter`, payload)
  },

  downloadReport: (payload) => {
    return new Promise((resolve, reject) => {
      API._post(`/v1/company/reports/download`, payload, true)
      .then((response) => {
        const href = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        const fileName = `${payload.report_type}_${(new Date()).toJSON().split("T")[0]}.xlsx`
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        resolve()
      }).catch((error) => {
        if (typeof(error.response)==="undefined") {
          alert("Sorry, there was an error trying to generate the report. Please try again a bit later, or contact your account manager to resolve this.")
        }
        if (error.response.status === 401) {
          alert("Sorry, this user is not allowed to generate reports at this time.")
        }
        reject()
      })  
    })
  },

  login: async (username, password) => {
    try {
      const user = await Auth.signIn(username.trim(), password.trim())
      if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        alert("The authentication system requires you to reset your password. Please contact your account manager, so we can take care of this for you.")
        return false;
      } else {
        return user;
      }
    } catch (error) {
      if (error.toString().indexOf('cannot be empty') >= 0) {
        alert('You need to specify a username and a password to log in. Please try again.')
        return false
      }
      console.log('Error while trying to sign in: ', error)
      alert("Sorry, we were unable to log you in with these account details. Please double-check your credentials, especially your password.")
      return false
    }
  },

}
export const Time = {
  
  zeroPad: (i) => {
    return i.toString().length < 2 ? `0${i}` : `${i}`
  },

  getShortFormatDate: (d) => {
    return (
      d.getFullYear() + '-' + Time.zeroPad(d.getMonth() + 1) + '-' + Time.zeroPad(d.getDate())
    )
  },

  shortFormatDateToFullDate: (d) => {
    if (d.length !== 10) return new Date() // Or null?
    let incomingYear = d.split('-')[0]
    let incomingMonth = d.split('-')[1]
    let incomingDay = d.split('-')[2]
    return new Date(Date.UTC(incomingYear, incomingMonth - 1, incomingDay, 0, 0, 0, 0))
  },

  getHumanReadableDate: (d) => {
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    return Time.zeroPad(d.getDate()) + ' ' + months[d.getMonth()] + ' ' + d.getFullYear()
  },  

  getDateByDayOffset: (start, days) => {
    var date = new Date(start || Date.now())
    var n = Number(days)
    date.setDate(date.getDate() + n)
    return date
  },  

}
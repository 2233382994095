import { Button } from "@geist-ui/core"
import { useStore } from "../stores/main"
import { UserCheck } from '@geist-ui/icons'
import { Sun } from '@geist-ui/icons'
import { Auth } from "aws-amplify"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

function MenuTitle(props) {
  return (
    <div style={{marginBottom:10, marginTop:30, fontWeight: "bold"}}>{props.title}</div>
  )
}

function MenuItem(props) {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  let basicStyle = {
    borderBottom: "1px solid #ddd", 
    paddingBottom: 10, 
    paddingTop: 10,
    fontSize: 15,
    display: "flex", 
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none"
  };
  let hoverStyle = {
    fontWeight: "bold"
  };
  let activeStyle = {
    fontWeight: "bold"
  }
  var menuStyle = hover ? {...basicStyle, ...hoverStyle} : basicStyle

  // Is the menu href active right now?
  let p = document.location.pathname;
  var isActive = false;
  if (p === props.href) isActive = true;
  if (isActive) {
    menuStyle = {...basicStyle, ...activeStyle}
  }
  return (    
    <div style={menuStyle} onClick={()=>{ if (props.href) { navigate(props.href) } }} onMouseOver={()=>{setHover(true)}} onMouseOut={()=>{setHover(false)}}>
      {props.children}
    </div>
  )
}

export default function Navigation() {

  const user = useStore((store) => store.user)
  const logout = useStore((store) => store.logout)

  return (
    <div style={{backgroundColor:"#fafafa", color: "#666", width: "100%"}}>
      <div>
        
        <div style={{margin:40, paddingTop:15, cursor: "pointer"}} onClick={() => {document.location.href='/?'}}>
          <div style={{fontSize: 13, color:"#213A9B", fontWeight: "bold", letterSpacing:1.3}}>REPORTING</div>
          <img style={{width:"60%"}} src="/logo.png" alt="Logo"/>
        </div>

        <div style={{margin:40, marginTop:50}}>
          <MenuTitle title={`Reports`}/>
          <MenuItem href="/report/costcenter"><UserCheck size={16}/> &nbsp; Cost Center Report</MenuItem>          
          <MenuItem href="/report/climate"><Sun size={16}/> &nbsp; Climate Impact Report</MenuItem>
          <MenuTitle title={`Administration`}/>
          <MenuItem href="/admin/costcenter"><UserCheck size={16}/> &nbsp; Cost Centers</MenuItem>          
        </div>

        <div style={{marginLeft: 40, marginTop:300, fontSize:14}}>
          {user.username || ""}
          <div style={{marginTop:10}}><Button ghost auto type="warning" scale={0.5} onClick={() => {
            Auth.signOut().then(() => {
              logout();
            })
          }}>Log out</Button></div>
        </div>

        <div style={{marginTop:50, marginLeft: 40}}>
          <div style={{ fontSize: 10, color: '#ccc', marginBottom: 20}}>
            Version: {window.fnzBuildVersion}
          </div>
        </div>
      </div>
    </div>
  )
}
import ExportDetails from "../components/ExportDetails";

export default function ReportCostCenterPage() {
  return (
    <div>
      <h3>Cost Center Report</h3>
      <br/>
      <ExportDetails report={"cost_center"}/>

    </div>
  )
}